<div class="wrap">

  <div class="login-modal">

    <img src="../assets/img/logo_vertical_pozitiv.svg"
         class="logo"
         alt="Nexttech"
    />

    <form (ngSubmit)="resetPassword()">

      <div class="flex-item">
        <mat-form-field>
          <input matInput placeholder="NEW PASSWORD" [formControl]="password" [type]="hide ? 'password' : 'text'"
                 class="sign-in-text">
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
          <mat-error *ngIf="password.invalid">You must enter a password</mat-error>
        </mat-form-field>
      </div>
      <div class="flex-item">
        <mat-form-field>
          <input matInput placeholder="NEW PASSWORD CONFIRM" [formControl]="passwordc"
                 [type]="hide ? 'password' : 'text'" class="sign-in-text">
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
          <mat-error *ngIf="passwordc.invalid">You must enter a password confirmation</mat-error>
        </mat-form-field>
      </div>
      <div class="flex-item">
        <div class="form-actions">
          <button mat-button color="primary">Reset password</button>
        </div>
      </div>
    </form>
  </div>
</div>
